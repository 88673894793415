import React, { Component } from 'react';
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html";

const sanitizeConf = {
  allowedTags: [],
  allowedAttributes: {}
};

export default class TitleBox extends Component {
  constructor(props) {
    super(props);

    this.contentEditable = React.createRef();

    this.state = { headingText: "" };

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    this.setState({
      // headingText: escapeHtml(this.props.name)
      headingText: sanitizeHtml(this.props.text, sanitizeConf)
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.text !== this.props.text) {
      this.setState({
        // headingText: escapeHtml(this.props.name)
        headingText: sanitizeHtml(this.props.text, sanitizeConf)
      })
    };
  }

  onDeleteClick(e) {
    e.preventDefault();
    this.props.onDeleteClick();
  }

  handleChange(e) {
    this.setState({
      headingText: e.target.value
    });
  }

  onBlur(e) {
    this.props.onTextChange(this.state.headingText);
  }

  onKeyDown(e) {
    if ( e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  }

  render() {
    return (
      <h2>
        <ContentEditable
          innerRef={this.contentEditable}
          html={this.state.headingText}
          onChange={this.handleChange}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          tagName='span'
          spellCheck='false' />
      </h2>
    );
  }
}