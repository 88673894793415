import React, { Component } from 'react';
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html";

const sanitizeConf = {
  allowedTags: [],
  allowedAttributes: {}
};

export default class ListItem extends Component {
  constructor(props) {
    super(props);

    this.contentEditable = React.createRef();

    this.state = { itemText: "" };

    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    this.setState({
      // itemText: escapeHtml(this.props.name)
      itemText: sanitizeHtml(this.props.name, sanitizeConf)
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.name !== this.props.name) {
      this.setState({
        // itemText: escapeHtml(this.props.name)
        itemText: sanitizeHtml(this.props.name, sanitizeConf)
      })
    };
  }

  onCheckboxChange(e) {
    // Remove this to have instant feedback, sometimes it can be confusing though.
    // e.preventDefault();

    this.props.onCheckboxClick(!this.props.ticked);
  }

  onDeleteClick(e) {
    e.preventDefault();
    this.props.onDeleteClick();
  }

  handleChange(e) {
    this.setState({
      itemText: e.target.value
    });
  }

  onBlur(e) {
    this.props.onTextChange(this.state.itemText);
  }

  onKeyDown(e) {
    if ( e.keyCode === 13) {
      e.preventDefault();
      e.target.blur();
    }
  }

  render() {
    let deleteClassList = "fa-btn float-right"
    if (!this.props.ticked)
      deleteClassList += " hidden";

    return (
      <li className="list-item">
        <input type="checkbox" checked={this.props.ticked} onChange={this.onCheckboxChange} />
        <ContentEditable
          className="flex-grow"
          innerRef={this.contentEditable}
          html={this.state.itemText}
          onChange={this.handleChange}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          tagName='span' />

          <a href="/#" className={deleteClassList} onClick={this.onDeleteClick}><i className="fa fa-trash"></i></a>
      </li>
    );
  }
}