import React, { Component } from "react";

import './FrontPage.css';

export default class FrontPage extends Component {
    constructor() {
        super();

        const lists = JSON.parse(localStorage.getItem('myLists') || '[]');

        this.state = {
            lists
        }
    }

    render() {
        const listItems = this.state.lists.map((list) => {      
            return (
                <div key={list.listId} className="ListListWrapper">
                    <div className="set-flex flex-grow">
                        <a href={`/l/${list.listId}`} className="ListLink">{list.title || 'Untitled Shopping List'}</a>    
                    </div>
                    <div className="set-flex">
                        <a href={`/d/${list.listId}`} className="fa-btn float-right"><i className="fa fa-trash"></i></a>
                    </div>
                </div>
            );
          });
        return (
            <div className="FrontPage">
                <div><img src="/logo.svg" alt="CloudList Logo" style={{height: "2.2em", marginTop: "2em", marginBottom: "0.7em"}} /></div>
                <div className="item-list">
                    {listItems}
                </div>
                <div><br /> </div>
                <div>
                    <a href="/new" className="btn btn-green"><i className="fa fa-plus"></i> Add</a>
                </div>
                {/* <div>
                    <button onClick={ window.location.reload }>Refresh Page</button>
                </div> */}
            </div>
        )
    }
}