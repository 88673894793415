import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import './index.css';
import App from './App';
import FrontPage from './FrontPage';
import ForgetList from './ForgetList';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Switch>
        <Route path="/l/:listId" component={App}>
        </Route>
        <Route path="/d/:listId" component={ForgetList}>
        </Route>
        <Route path="/new">
          <Redirect to={ `/l/${uuidv4()}` } />
        </Route>
        <Route path="/">
          <FrontPage />
        </Route>
      </Switch>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
