import React, { Component } from 'react';

export default class StatusIndicator extends Component {
  constructor(props) {
    super(props);

    this.iconList = {
      syncing: "fas fa-sync",
      error: "fas fa-exclamation-circle icon-red",
      connecting: "fas fa-phone-volume",
      connected: "fas fa-check",
    }

    this.state = { status: this.iconList.connecting };
  }

  componentDidMount() {
    this.setState({
      status: this.iconList[this.props.status]
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.status !== this.props.status) {
      this.setState({
        status: this.iconList[this.props.status]
      })
    };
  }

  render() {
    return (
      <div className="status-icon">
        { this.props.status === "error" &&
          <span style={{fontSize: "12px", verticalAlign: "top"}}>Connection Error </span>
        }
        <i className={ this.state.status } aria-hidden="true"> </i>
      </div>
    );
  }
}