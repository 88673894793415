import React, { Component } from 'react';
import './App.css';

class ForgetList extends Component {
  constructor() {
    super();

    this.deleteListFromCache = this.deleteListFromCache.bind(this);
  }

  deleteListFromCache(e) {
    e.preventDefault();
    console.log('deleting from cache');
    const listId = this.props.match.params.listId;
    // Save list id to local storage
    try {
      const myListsStr = localStorage.getItem('myLists') || '[]';
      let myLists = JSON.parse(myListsStr);
      localStorage.setItem('myLists', JSON.stringify(myLists.filter((item)=>item.listId !== listId)));
    } catch {
      console.log('error updating list name');
    }
    window.location.href = '/';
  }

  render() {
    try {
      const res = (
        <div className="App">
          <div>Are you sure you want to delete list:</div>
          <div>{ JSON.parse(localStorage.getItem('myLists')).filter((item) => item.listId === this.props.match.params.listId)[0].title }</div>
          <div>{ this.props.match.params.listId }</div>
          <div>
            <button onClick={ (e) => this.deleteListFromCache(e) }>YES</button>
            <button onClick={ () => { window.location.href = '/' }}>NO</button>
          </div>
        </div>
      );

      return res;
    } catch {
      return (
        <div>An error</div>
      )
    }
  }
}

export default ForgetList;
