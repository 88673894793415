import React, { Component } from 'react';
import './App.css';
import { v4 as uuidv4 } from 'uuid';

import ListItem from './ListItem';
import NewItemForm from './NewItemForm';
import TitleBox from './TitleBox';
import StatusIndicator from './StatusIndicator';
require('firebase/firestore');

const websocketURL = process.env.REACT_APP_STAGE === 'production'
  ? 'wss://ws.cloudlist.be/'
  : 'wss://ws.dev.cloudlist.be/'

class App extends Component {
  constructor() {
    super();

    this.state = {
      title: 'loading...',
      items: [],
      cachedChanges: [],
      listId: '',
      status: 'disconnected'
    };

    this.addItem = this.addItem.bind(this);
    this.setItemGot = this.setItemGot.bind(this);
    this.deleteSelected = this.deleteSelected.bind(this);
    this.shareButtonClicked = this.shareButtonClicked.bind(this);
    this.updateItemText = this.updateItemText.bind(this);
    this.applyAction = this.applyAction.bind(this);

    this.updateListTitle = this.updateListTitle.bind(this);
    this.updateTitleInCache = this.updateTitleInCache.bind(this);

    this.wsOpen = this.wsOpen.bind(this);
    this.wsMessage = this.wsMessage.bind(this);
    this.wsClose = this.wsClose.bind(this);
  }

  applyAction(action, originalItems) {
    // Copy original list.
    let items = [...originalItems];

    switch (action.action) {
      case 'addItem':
        items.push(action.item);
        break;
      case 'deleteItem': {
        // Clone list.
        items = items.filter((value) => value.itemId !== action.item.itemId);
        break;
      }
      case 'updateItem': {
        // Get index of item.
        const index = items.findIndex((item) => item.itemId === action.item.itemId);
        // Check it was found.
        if ( index < 0 ) {
          break;
        }
        // Update any updated fields
        let item = { ...items[index], ...action.item };
        // Re-insert
        items[index] = item;
        break;
      }
      default:
        console.log('unknown action');
        break;
    }

    return items;
  }

  wsInit() {
    // Initialise status.
    this.setState({ status: "connecting" })
    this.ws = new WebSocket(websocketURL);
    this.ws.onopen = this.wsOpen;
    this.ws.onmessage = this.wsMessage;
    this.ws.onclose = this.wsClose;
  }

  wsOpen(event) {
    this.setState({ status: "connected" })
    console.log(`ws open: ${JSON.stringify(event)}`);
    console.log('sending subscribe');
    this.wsSend(JSON.stringify({
      action: 'subscribe',
      listId: this.state.listId,
    }));
  }

  wsMessage(event) {
    const msg = JSON.parse(event.data);
    if ('action' in msg && msg.action === 'updateList') {
      const cachedChanges = this.state.cachedChanges.filter((change) => change.actionId !== msg.actionId);
      this.setState({
        title: msg.metadata.title,
        cachedChanges,
        status: cachedChanges.length === 0 ? 'connected' : 'syncing',
      });
    } else if ('action' in msg) {
      const items = this.applyAction(msg, this.state.items);

      const cachedChanges = this.state.cachedChanges.filter((change) => change.actionId !== msg.actionId);

      this.setState({
        items,
        cachedChanges,
        status: cachedChanges.length === 0 ? 'connected' : 'syncing',
      })
    } else if ('items' in msg) {
      this.updateTitleInCache(msg.metadata.title);
      this.setState({
        title: msg.metadata.title,
        items: msg.items.sort((a,b)=>(a.created-b.created)),
        cachedChanges: [],
        status: 'connected',
      })
    }
  }

  wsClose(event) {
    this.setState({ status: "closed" })
    console.log(`ws close: ${JSON.stringify(event)}`);
    console.log('ws reconnecting in 1 second');
    setTimeout(this.wsInit(), 1000);
  }

  wsSend(action) {
    try {
      this.setState({ status: "syncing" })
      this.ws.send(action);
    } catch {
      // If an action doesn't send, remove it from the cache.
      const parsedAction = JSON.parse(action);
      let cachedChanges = [ ...this.state.cachedChanges ];
      if ('actionId' in parsedAction) {
        cachedChanges = cachedChanges.filter((change) => change.actionId !== parsedAction.actionId);
      }
      this.setState({
        status: 'error',
        cachedChanges
      });
    }
  }

  updateListTitle(newTitle) {
    if ( newTitle === "" ) {
      console.log('trying to change title to empty value')
      return;
    }

    const action = {
      action: 'updateList',
      actionId: uuidv4(),
      listId: this.state.listId,
      metadata: {
        title: newTitle,
      },
    };

    this.wsSend(JSON.stringify(action));

    // Update the title locally.
    this.setState({
      title: newTitle,
    });
    // Make sure the cached title is up to date too.
    this.updateTitleInCache(newTitle);
  }

  updateTitleInCache(title) {
    console.log('updating cache ' + title)
    const listId = this.props.match.params.listId;
    // Save list id to local storage
    try {
      const myListsStr = localStorage.getItem('myLists') || '[]';
      let myLists = JSON.parse(myListsStr);
      const index = myLists.findIndex((value) => value.listId === listId);
      myLists[index] = {
        ...myLists[index],
        title
      }
      localStorage.setItem('myLists', JSON.stringify(myLists));
    } catch {
      console.log('error updating list name');
    }
  }

  componentDidMount() {
    const { match: { params: { listId } } } = this.props

    // Save list id to local storage
    try {
      const myListsStr = localStorage.getItem('myLists') || '[]';
      let myLists = JSON.parse(myListsStr);
      if(myLists.findIndex((value) => value.listId === listId) === -1) {
        myLists.push({listId});
        localStorage.setItem('myLists', JSON.stringify(myLists));
      }
    } catch {
      localStorage.setItem('myLists', JSON.stringify([listId]));
    }

    this.setState({
      listId
    })

    this.wsInit()
  }

  async addItem(newItemText) {
    if ( newItemText === "" ) {
      console.log('trying to add empty item')
      return;
    }

    const action = {
      action: 'addItem',
      actionId: uuidv4(),
      listId: this.state.listId,
      item: {
        description: newItemText,
      },
    };

    this.wsSend(JSON.stringify(action));

    this.setState({
      cachedChanges: [...this.state.cachedChanges, action]
    })
  }

  async setItemGot(item, newState) {
    const action = {
      action: 'updateItem',
      actionId: uuidv4(),
      listId: this.state.listId,
      item: {
        ...item,
        ticked: newState
      },
    };

    this.wsSend(JSON.stringify(action));

    this.setState({
      cachedChanges: [...this.state.cachedChanges, action]
    })
  }

  async updateItemText(item, newItemText) {
    const action = {
      action: 'updateItem',
      actionId: uuidv4(),
      listId: this.state.listId,
      item: {
        ...item,
        description: newItemText
      },
    };

    this.wsSend(JSON.stringify(action));

    this.setState({
      cachedChanges: [...this.state.cachedChanges, action]
    });
  }

  async deleteItem(item) {
    const action = {
      action: 'deleteItem',
      actionId: uuidv4(),
      listId: this.state.listId,
      item,
    };

    this.wsSend(JSON.stringify(action));

    this.setState({
      cachedChanges: [...this.state.cachedChanges, action]
    })
  }

  async deleteSelected(itemId) {
    // Make sure the cached changes are included.
    let updatedItems = [...this.state.items];
    this.state.cachedChanges.forEach((change) => { updatedItems = this.applyAction(change, updatedItems); });

    // Then delete everything.
    const items = updatedItems.filter((item) => item.ticked);
    items.forEach(item => { this.deleteItem(item); });
  }

  shareButtonClicked(e) {
    if (navigator.share) {
      console.log("sharing mobile");
      navigator.share({
        title: this.state.title,
        url: document.location.href,
      }).then(() => {
        console.log('Thanks for sharing!');
      })
      .catch(() => {
        console.log('User aborted share.')
      });
    } else {
      console.log("sharing desktop - not implemented");
    }
  }

  render() {
    let updatedItems = [...this.state.items];
    this.state.cachedChanges.forEach((change) => { updatedItems = this.applyAction(change, updatedItems); });
    
    const listItems = updatedItems.map((item) => {
      if (item.itemId === undefined) item.itemId = uuidv4();

      return (
        <ListItem 
          key={ item.itemId }
          name={ item.description }
          ticked={ item.ticked }
          onCheckboxClick={(newState) => this.setItemGot(item, newState)}
          onDeleteClick={ () => { this.deleteItem(item); } }
          onTextChange={ (newText) => { this.updateItemText(item, newText); } }
        />
      );
    });

    return (
      <div className="App">
        <header className="App-header">
          {/* <div> */}
            <a href="/" className="back-button"><i className="fa fa-arrow-left" aria-hidden="true"></i> </a>
            {/* <a href="/"><img src="/logo.svg" alt="CloudList Logo" style={{height: "2em"}} /></a> */}
            <StatusIndicator status={ this.state.status } />
          {/* </div> */}
          <TitleBox text={ this.state.title } onTextChange= { (newTitle) => { this.updateListTitle(newTitle); } } />          
        </header>

        <div className="item-list">
          <ul>
            { listItems }
          </ul>
          <NewItemForm onAddItem={ this.addItem } />
        </div>

        <div>
          <button onClick={ this.deleteSelected } className="btn btn-red">Delete Selected</button>&nbsp;
          { navigator.share && (
            <button onClick={ this.shareButtonClicked } className="btn btn-green">Share</button>
          )}
        </div>
      </div>
    );
  }
}

export default App;
