import React, { Component } from "react";
import './NewItemForm.css';

export default class NewItemForm extends Component {
  constructor() {
    super();

    this.addItem = this.addItem.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state  ={
      newItemText: ""
    };
  }

  addItem(e) {
    e.preventDefault();
    this.props.onAddItem(this.state.newItemText);
    this.setState({newItemText: ''});
    this.textInput.focus();
  }

  handleChange(e) {
    e.preventDefault();
    this.setState({newItemText: e.target.value});
  }

  onKeyDown(e) {
    if ( e.keyCode === 13) {
      e.preventDefault();
      this.addItem(e);
    }
  }

  render() {
    return (
      <div className="new-item-form">
        <input 
          className="new-item-text-box"
          ref={(ref) => { this.textInput = ref; }}
          type="text"
          placeholder="Add item..."
          value={this.state.newItemText}
          onChange={this.handleChange}
          onKeyDown={this.onKeyDown} />

        <button
          className="btn btn-blue"
          onClick={this.addItem}
          >ADD</button>
      </div>
    );
  }
}